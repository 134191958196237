.availability_cont{
    max-width: 826px;
    width: 100%;
    border-radius: 16px;
    background:  #FAF7FD;
    padding: 24px;
}
.months_b{
    border-radius: 100px;
    background: #FFF;
    padding: 12px 32px;
    height: 48px;
}
.months_b_item{
    cursor: pointer;
    color: #5D5567;
    margin-right: 8px;
}
.months_b_item:last-child{
    margin-right: 0px;
}
.months_b_item_active{
    cursor: pointer;
    color: white;
    border-radius: 100px;
    background: #6900BA;
    padding: 8px 12px;
    width: max-content;
    margin-right: 8px;
}
.months_b_item_active:last-child{
    margin-right: 0px;
}