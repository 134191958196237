.esti_control_cont {
    margin-top: 120px;
}
.esti_control_cont img{
   max-width: 744px;
    width: 1400%;
}
.esti_control_cont_b2{
    max-width: 741px;
    width: 100%;
    margin-left: 32px;
}
.esti_control_cont_b2 button{
    max-width: 240px;
    width: 100%;
    margin-top: 40px;
}