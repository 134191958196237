.css-14pt78w-MuiSlider-rail {
    background: #ead9ef !important;;
    border-radius: 200px !important;
    height: 13px !important;
}
.css-eg0mwd-MuiSlider-thumb {
    width: 0px !important;
    height: 0px !important;
}
.css-1gv0vcd-MuiSlider-track {
    border-radius: 200px !important;
    background:  #6900BA !important;;
    height: 13px !important;
}

.css-eg0mwd-MuiSlider-thumb::after {
    width: 27px !important;
    height: 27px !important;
    background: #6900ba !important;
}
.css-nnid7-MuiSlider-valueLabel {
     background: none !important;
    top: 60px !important;
    /*display: none !important;*/
}
.css-d3wcwz-MuiTypography-root {
    text-align: left !important;
    border-radius: 200px !important;
    background: #FAF7FD !important;
    padding: 12px !important;
    width: max-content !important;
}

.close_icon{
    margin-left: 4px;
    cursor: pointer;
}
.css-7drnjp:hover, .css-7drnjp.Mui-focusVisible {
    background:  #6900ba  !important;
}
.css-b04pc9 {
    background: #ead9ef !important;
    border-radius: 200px !important;
    height: 13px !important;
}
.css-1t2bqnt{
    border-radius: 200px !important;
    background: #6900BA !important;
    height: 13px !important;
}
.css-7drnjp::after {
    width: 27px !important;
    height: 27px !important;
    background: #6900ba !important;
}
.css-7drnjp:hover, .css-7drnjp.Mui-focusVisible {
    box-shadow: none !important;
}
.css-7drnjp.Mui-active{
    box-shadow: none !important;
}
.css-3besu {
    background: none !important;
    top: 60px !important;
    /*display: none !important;*/
}

