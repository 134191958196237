.home_block5_cont {
    margin-top: 120px;
}

.accordion {
    max-width: 744px;
    width: 100%;
    margin: 32px auto 0px;
}

.accordion_block {
    padding: 16px;
    border-radius: 10px;
    border: 1px solid  #D8D6DB;
}
.accordion_block_active {
    padding: 16px;
    border-radius: 10px;
    border: none;
    background:  #F3F0F5;
}
.tabs_block {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabs_block button {
   padding: 0px 32px 12px;
}
.tabs_block button:first-child {
    margin-right: 8px;
}

.showTab {
    display: block;
}

.hideTab {
    display: none;
}
.tab_active{
    border-bottom: 1px solid  #6900BA;
}