.home_b3{
    margin-top: 120px;
}
/*.swiper-button-prev {*/
/*    position: absolute;*/
/*    width: 48px !important;*/
/*    height: 48px !important;*/
/*    content: url("../../../../assets/images/icons/prev_white_icon.png");*/
/*}*/
/*.swiper-button-next {*/
/*    position: absolute;*/
/*    width: 48px !important;*/
/*    height: 48px !important;*/
/*    content: url("../../../../assets/images/icons/next_purple_icon.png");*/
/*}*/
.employer_swiper_slider .swiper-slide{
    max-width: 356px !important;
    width: 100% !important;
    margin-right: 28px !important;
}
/*.question_swiper_slider{*/
/*   position: unset !important;*/
/*}*/
/*.swiper-button-next:after .swiper-rtl .swiper-button-prev:after {*/
/*    content: url("../../../../assets/images/icons/next_purple_icon.png") !important;*/
/*}*/
/*.swiper-button-prev:after .swiper-rtl .swiper-button-next:after{*/
/*    content: url("../../../../assets/images/icons/prev_white_icon.png") !important;*/
/*}*/
/*.swiper-button-prev:after {*/
/*   content: url("../../../../assets/images/icons/prev_white_icon.png") !important;*/
/*}*/
/*.swiper-button-next:after {*/
/*    content: url("../../../../assets/images/icons/next_purple_icon.png") !important;*/
/*}*/
.employer_swiper_slider{
    position: unset !important;
    margin-top: 32px !important;
}
.employerSwiperSlide{
   max-width: 356px;
    width: 100%;

}
.employerSwiperSlide:hover{
  border: 1px solid #6900ba;
    border-radius: 16px;

}
.employerSwiperSlide_infoB{
   border-top: 1px solid #F0E6FB;
    border-bottom: 1px solid #F0E6FB;
    padding: 12px 0px;
    margin-top: 12px;
}
.employerSwiperSlide_infoB_item:first-child{
    margin-right: 16px;
}

.employerSwiperSlide_infoB_item p{
    margin-left: 4px;
}

.babySicker_pic{
    /*width: 90px;*/
    /*height: 90px;*/
    z-index: -1;
    max-width: 356px;
    width: 100%;
    border-radius: 16px 16px 0px 0px;
    background: lightgray 0px -76.294px / 100% 206.468% no-repeat, #6900BA;
}
.employerSwiperSlide_main_block{
    border-radius: 16px;
    border: 1px solid  #F5E5F9;
    background: #FFF;
    padding: 24px;
    margin-top: -40px;
    max-width: 356px;
    width: 100%;
}
.certificate_pic{
    width: 32px;
    height: 32px;
}
.employerSwiperSlide_location_b{
    padding: 12px 0;
    border-bottom: 1px solid  #F5E5F9;
}
.employerSwiperSlide_location_b img{
    width: 32px;
    height: 32px;
}
.employerSwiperSlide_location_b p{
   margin-left: 8px;
}


.swiper-button-prev, .swiper-rtl .swiper-button-next {
    left:  -70px !important;
    content: url("../../../../assets/images/icons/prev_white_icon.png") !important;
    width: 48px !important;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right:  -70px !important;
    content: url("../../../../assets/images/icons/next_purple_icon.png") !important;
    width: 48px !important;
}