.back_pic_b{
    background: url("../../assets/images/Home_pic.png") no-repeat;
    width: 100%;
    z-index: -1;
    height: 100vh;
}
/*.home_pic {*/
/*    position: relative;*/
/*    width: 100%;*/
/*}*/

.home_block1 {
    position: absolute;
    top: 25%;
    left: 10%;
    max-width:600px;
    width: 100%;
}

.home_block1_btns_b button:first-child {
    padding: 12px 24px;
    border-radius: 200px;
    margin-right: 24px;
    max-width: 240px;
    width: 100%;
}

.home_block1_btns_b button:last-child {
    padding: 12px 24px;
    border: 1px solid #6900BA;
    border-radius: 200px;
    max-width: 240px;
    width: 100%;
}

.home_block2 {
    margin: auto;
    position: absolute;
    top: 70%;
    left: 9%;
}

.home_block2_item {
    max-width: 485px;
    width: 100%;
    border-radius: 20px;
    padding: 35px 40px;
    background: white;
    box-shadow: 12px 10px 4px 0px rgba(250, 247, 253, 0.50);
    margin-right: 32px;
    height: 280px;
}
.home_block2_item:last-child {
    margin-right: 0px;
}
.home_block2_item:hover {
  border:1px solid #6900BA;
}
.home_block2_item img {
    max-width: 100px;
    width: 100%;
}