.find_nanny_calendar
.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: none;
    border-radius: 20px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    padding: 32px;
}