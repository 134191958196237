.homeBlock4_cont{
    margin-top: 120px;
    width: 100%;
    background:  #FAF7FD;
    padding:120px;
}
.questionSwiperSlide{

}

.question_Swiper_Slide .swiper-slide{
    max-width: 356px !important;
    width: 100% !important;
    margin-right: 28px !important;
}

.question_swiper_slider{
    position: unset !important;
    margin-top: 32px !important;
}
.questionSwiperSlide{
    max-width: 356px;
    width: 100%;

}
.questionSwiperSlide:hover{
    border: 1px solid #6900ba;
    border-radius: 16px;

}
.questionSwiperSlide_lastB{
    border-top: 1px solid #F0E6FB;
    border-bottom: 1px solid #F0E6FB;
    padding: 12px 0px;
    margin-top: 12px;
}
.questionSwiperSlide_main_block{
    border-radius: 16px;
    border: 1px solid  #F5E5F9;
    background: #FFF;
    padding: 24px;
    margin-top: -40px;
    max-width: 356px;
    width: 100%;
}
.certificate_pic{
    width: 32px;
    height: 32px;
}
.questionSwiperSlide_lastB_item:first-child{
    margin-right: 16px;
}

.questionSwiperSlide_lastB_item p{
    margin-left: 4px;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
    left:  -70px !important;
    content: url("../../../../assets/images/icons/prev_white_icon.png") !important;
    width: 48px !important;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right:  -70px !important;
    content: url("../../../../assets/images/icons/next_purple_icon.png") !important;
    width: 48px !important;
}