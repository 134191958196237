.GăseșteOBonă_gen_pic_b{
    width: 100%;
    position: relative;
}
.GăseșteOBonă_gen_pic_b img{
    width: 100%;
}
.GăseșteOBonă_gen_pic_b div{
   position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 651px;
    width: 100%;
    font-size: 48px;
    line-height: 62px;
    letter-spacing: 0em;
    text-align: center;
}