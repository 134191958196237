.specialist_page_cont {
    max-width: 1262px;
    width: 100%;
    margin: 140px auto 100px;
}

.specialist_page_cont .specialist_page_cont_b1 {
    margin-right: 32px;
    max-width: 356px;
    width: 100%;

}
.specialist_page_cont_b1_infoB{
    border-top: 1px solid #F0E6FB;
    border-bottom: 1px solid #F0E6FB;
    padding: 12px 0px;
    margin-top: 12px;
}
.specialist_page_cont_b1_infoB_item:first-child{
    margin-right: 16px;
}

.specialist_page_cont_b1_infoB_item p{
    margin-left: 4px;
}

.babySicker_pic{
    /*width: 90px;*/
    /*height: 90px;*/
    z-index: -1;
    max-width: 356px;
    width: 100%;
    border-radius: 16px 16px 0px 0px;
    background: lightgray 0px -76.294px / 100% 206.468% no-repeat, #6900BA;
}
.specialist_page_cont_b1_main_block{
    border-radius: 16px;
    border: 1px solid  #F5E5F9;
    background: #FFF;
    padding: 24px;
    margin-top: -40px;
    max-width: 356px;
    width: 100%;
}
.certificate_pic{
    width: 32px;
    height: 32px;
}
.specialist_page_cont_b1_location_b{
    padding: 12px 0;
    border-bottom: 1px solid  #F5E5F9;
}
.specialist_page_cont_b1_location_b img{
    width: 32px;
    height: 32px;
}
.specialist_page_cont_b1_location_b p{
    margin-left: 8px;
}


.specialist_page_cont_b2 {
    border-radius: 16px;
    border: 1px solid #F5E5F9;
    background: #FFF;
    padding: 24px;
}

.documentation_block_item {
    border-radius: 16px;
    background: #FAF7FD;
    max-width: 401px;
    width: 100%;
    padding: 14px 12px;
}

.documentation_block_item:first-child {
    margin-right: 24px;
}

.documentation_block_item img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.documentation_block_item div {
    color: #0D0016;
    margin-top: 4px;
}

.documentation_block_item span {
    color: #847B8E;
    text-decoration: underline;
    margin-top: 4px;
    cursor: pointer;
}

.documentation_block2 .documentation_block_item {
    display: flex;
    padding: 12px 30px 12px 24px;
    border-radius: 16px;
    background: #FAF7FD;
}

.documentation_block2 .documentation_block_item img {
    width: 63px;
    height: 63px;
    margin-bottom: 4px;
}

.general_info_cont {
    padding: 24px;
    border-radius: 16px;
    background: #FAF7FD;
    width: 100%;
}

.general_info_block {
    grid-column-gap: 48px;
    grid-row-gap: 24px;
}

.general_info_block_item {
    max-width: 365px;
    width: 100%;
    padding-bottom: 11px;
    border-bottom: 1px solid #F0E6FB;
}

.general_info_block_item img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
}

.general_info_block_item div {
    margin-right: 24px;
}

.translation_icon {
    margin-right: 4px;
    width: 24px;
    height: 24px;
}

.skills_cont {
    width: 100%;
    border-radius: 16px;
    background: #FAF7FD;
    padding: 16px 0px;
}

.skills_cont_item {
    max-width: 115px;
    width: 100%;
    border-right: 1px solid rgb(222, 209, 243);
    border-left: 1px solid rgb(222, 209, 243);
    padding: 0px 21px;
}
.skills_cont_item:first-child {
    border-left: none;
}
.skills_cont_item:last-child {
    border-right: none;
    max-width: 150px;
}
.skills_cont_item_inner{
    max-width: 90px;
    width: 100%;
    margin: auto;
}


.skills_cont_item img {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
}
