.filtration_cont {
    margin-top: 140px;
}

.filter_btn {
    max-width: 240px;
    width: 100%;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 200px;
}
.filter_btn img{
 margin-right: 10px;
}
.search_block{
    margin:  0px 16px;
    max-width: 486px;
    width: 100%;
    position: relative;
}
.search_block input{
    border-radius: 200px;
    border: 1px solid  #B4B2B7;
    width: 100%;
    padding: 12px 24px 12px 38px;
}
.search_block input::placeholder{
  color: #B4B2B7;
}
.search_block img{
  position: absolute;
    top: 32%;
    left: 3%;
}
.find_in_map_btn{
    max-width: 240px;
    width: 100%;
    border-radius: 200px;
    border: 1px solid  #6900BA;
    display:  flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
}
.find_in_map_btn img{
    margin-right: 10px;
}
.filtration_content{
    margin-top: 40px;
}
.apply_btn{
    margin-left: 35px;
    padding: 12px 24px;
    color: #6900ba;
    border-radius: 200px;
    border: 1px solid  #6900BA;
}
.range_b{
    max-width: 486px;
    width: 100%;
    margin-right: 52px;
}
.range_b:last-child{
    margin-right: 0px;
}
.filtred_items_b{
    margin-top: 61px;
    width: 100%;
}
.filtred_items_b #non-linear-slider{
    margin-right: 12px;
    text-align: left !important;
    border-radius: 200px !important;
    background: #FAF7FD !important;
    padding: 12px !important;
    width: max-content !important;
}
.clear_filters_btn{
    margin-left: 6px;
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap;
}
.css-d3wcwz-MuiTypography-root {
    text-align: left !important;
    border-radius: 200px !important;
    background: #FAF7FD !important;
    padding: 12px !important;
    width: max-content !important;
}
.css-1tqv6h6 {
    text-align: left !important;
    border-radius: 200px !important;
    background: #FAF7FD !important;
    padding: 12px !important;
    width: max-content !important;
}
