.header_login_container {
    padding: 21.5px 0px;
    position: fixed;
    top: 0px;
    margin: auto;
    width: 100%;
    z-index: 999;
    background: #F5E5F9;
}

.logo {
    cursor: pointer;
}

.header_btn {
    text-decoration: none;
    margin-right: 32px;
    cursor: pointer;
}
.header_btn:hover, .header_btn_active:hover {
  transform: scale(1.03);
    color: #6900BA;
}

.header_btn_active {
    text-decoration: none;
    margin-right: 32px;
    cursor: pointer;
    color: #6900BA;
}

.signUp_btn {
    max-width: 240px;
    width: 100%;
    height: 54px;
    padding: 12px 24px;
    border-radius: 200px;
    border: 1px solid #6900BA;
    gap: 10px;
    margin-left: 32px;
}