.our_specialists_cont{
    margin-top: 48px;
}
.load_more_error{
    color: red;
    text-align: center;
}
.our_specialists_cont_item{
    max-width: 356px;
    width: 100%;
}